export default {
  proxy_path: "https://3v19ps7rzj.execute-api.eu-west-1.amazonaws.com/api",
  proxy_path_geojson_api: "https://7or8sghmwl.execute-api.eu-west-1.amazonaws.com/api",
  google_api_key: "AIzaSyD84bsWJslpBGrsiinmyTx5aLPpbXFz0a8",
  main_company_term_id: "6",
  crm_base_url: "staging.europe.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.staging.europe.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.staging.europe.forwoodsafety.com",
    APP_CLIENT_ID: "4pgc330k9hj0kcde7le3m2ae1h",
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://w53htop2rb.execute-api.eu-west-1.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.staging.europe.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
